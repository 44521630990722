import httpClient from '../../httpClient';
import WelcomeResponseModel from '@/domains/home/models/WelcomeResponseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/market/dashboard/account/setup';

export const welcomePartner = (): Promise<WelcomeResponseModel | ErrorModel> => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new WelcomeResponseModel(response))
    .catch((error) => error);
};
