import BaseModel from '../../../api/models/BaseModel';

class WelcomeResponseModel extends BaseModel {
  public payload: {
    partner_account: boolean;
    partner_email: boolean;
    development_store: boolean;
    app_created: boolean;
    account_progress: number;
    partnership_contract: boolean;
  };
  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default WelcomeResponseModel;
